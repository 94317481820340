import { connect } from 'react-redux';
import propTypes from 'prop-types';
import Login from '../components/Login';
import * as actionCreators from '../services/actions/LoginActions';

//Map state to props
const mapStateToProps = (state) => ({
    data: state.login
});

Login.propTypes = {
    data: propTypes.object.isRequired,
}

export default connect(mapStateToProps, actionCreators)(Login);