import { connect } from 'react-redux';
import propTypes from 'prop-types';
import MatchScore from '../components/MatchScore/MatchScore';
import * as actionCreators from '../services/actions/MatchScoreActions';

/**
 * Mat State to props
 */
const mapStateToProps = (state) => ({
    data: state.matchScore.matchScore
});

MatchScore.propTypes = {
    data: propTypes.object.isRequired
}

export default connect(mapStateToProps, actionCreators)(MatchScore);