import $ from 'jquery';
import * as config from '../constants/config';
import LocalStorage from '../storage/LocalStorage';

const defaultMediaType = 'application/json';
const baseURL = config.apiUrl;

export function get(
    url,
    data,
    fetchObject = 'data',
    includeAuth = true,
    includeGeneric400Text = true
) {
    return new Promise((resolve, reject) => {
        $.ajax({
            beforeSend: function (request) {
                request.setRequestHeader("Accept", defaultMediaType);
                if (includeAuth) {
                    request.setRequestHeader(
                        "Authorization",
                        LocalStorage.getAccessToken()
                    );
                }
            },
            url: baseURL + url,
            crossDomain: true,
            dataType: "json",
            data: data,
            type: "GET",
            statusCode: {
                200: data => {
                    resolve(data);
                },
                400: data => {
                    reject(
                        includeGeneric400Text
                            ? `There was an error while attempting to fetch the ${fetchObject}. ${data.responseText
                            }`
                            : data.responseText
                    );
                },
                401: () => {
                    reject(`You are not authorized to fetch the ${fetchObject}.`);
                },
                403: data => {
                    reject(data.responseText);
                },
                406: () => {
                    reject(
                        `The specified accepted media type is not a valid output type from the server`
                    );
                },
                500: function () {
                    reject(
                        `There was an error while attempting to fetch the ${fetchObject}. If the problem persists, contact support at ${config.supportEmail}`
                    );
                }
            }
        });
    });
}

export function post(
    url,
    data,
    postObject = "data",
    includeAuth = true
) {
    return new Promise((resolve, reject) => {
        $.ajax({
            beforeSend: function (request) {
                request.setRequestHeader("Accept", defaultMediaType);
                if (includeAuth) {
                    request.setRequestHeader(
                        "Authorization",
                        LocalStorage.getAccessToken()
                    );
                };
            },
            url: baseURL + url,
            crossDomain: true,
            dataType: "json",
            type: "POST",
            data: data,
            statusCode: {
                200: function (data) {
                    resolve(data);
                },
                201: function (data) {
                    resolve(data);
                },
                400: function (data) {
                    reject(data.responseText);
                },
                401: () => {
                    reject(`You are not authorized to fetch the ${postObject}.`);
                },
                403: (data) => {
                    reject(data.responseText);
                },
                404: function (data) {
                    reject(data.responseText);
                },
                406: () => {
                    reject(`The specified accepted media type is not a valid output type from the server`);
                },
                409: (data) => {
                    reject(data.responseText);
                },
                500: function () {
                    reject(`There was an error while attempting to add the ${postObject}. If the problem persists, contact support at ${config.supportEmail}`);
                }
            }
        });
    });
}