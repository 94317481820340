import * as actionTypes from '../../constants/actionTypes';

export const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER
    }
}

export const hideLoader = () => {
    return {
        type: actionTypes.HIDE_LOADER
    }
}