import axios from "axios";
import * as config from '../constants/config';

//Create a instance
const instance = axios.create({
    baseURL: config.apiUrl,
    headers: {
        'content-type': 'application/json',
    }
});

export default {
    //Get data from the server by method = GET
    getData: (URL, parameters = {}) =>
        instance({
            'method': 'GET',
            'url': URL,
            'params': parameters
        }),
    //Post data on the server by method = POST
    postData: (URL, parameters = {}) =>
        instance({
            'method': 'POST',
            'url': URL,
            'data': parameters
        }),
}