import objectAssign from "object-assign";
import * as actionTypes from '../../constants/actionTypes';
import initialState from './initialState';

export default function MatchListReducers(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_TOURNAMENT_DETAILS:
            return objectAssign({}, state, {
                matchList: {
                    ...state.matchList,
                    loading: false,
                    tournament: action.data.tourArr,
                    category_id: action.data.category_id,
                    categoryArr: action.data.categoryArr,
                    gender: action.data.gender,
                    genderArr: action.data.genderArr,
                    rounds: action.data.rounds,
                    roundAndGroup: action.data.roundAndGroup,
                }
            });
        case actionTypes.FETCH_MATCHLIST_SUCCESS:
            return objectAssign({}, state, {
                matchList: {
                    ...state.matchList,
                    matches: action.data
                }
            });
        case actionTypes.CHANGE_GENDER:
            return objectAssign({}, state, {
                matchList: {
                    ...state.matchList,
                    gender: action.data
                }
            });
        case actionTypes.CHANGE_CATEGORY:
            return objectAssign({}, state, {
                matchList: {
                    ...state.matchList,
                    category_id: action.data
                }
            });
        case actionTypes.CHANGE_ROUND:
            return objectAssign({}, state, {
                matchList: {
                    ...state.matchList,
                    rounds: action.data
                }
            });
        default:
            return state;
    }
}