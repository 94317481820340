import React from "react";
import * as mui from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import '../App.css';
import logo from '../logo.png';
import * as config from '../constants/config';
import LocalStorage from '../storage/LocalStorage';
import AppDrawer from "./AppDrawer";

const AppHeader = (props) => {
    const prevPage = props.page;
    const user = LocalStorage.getLoggedInUser() || {};
    /*** Render Output ***/
    return (
        <mui.AppBar position="static" color="primary">
            <mui.Container component="div" maxWidth="xs">
                <mui.Toolbar variant="dense" disableGutters sx={{ px: 0 }}>
                    {(LocalStorage.isLoggedIn()) ? (
                        <>
                            <AppDrawer />
                            <mui.Avatar
                                alt={config.siteTitle}
                                src={logo}
                                sx={{ width: 30, height: 30 }}
                            />
                            <mui.Box sx={{ flexGrow: 1 }} />
                            <mui.Box className="inline-text">
                                <mui.Typography noWrap component="div" sx={{ mr: 1 }}>
                                    {user.first_name + ' ' + user.last_name}
                                </mui.Typography>
                                <mui.Avatar
                                    src={user.profile_image}
                                    alt={user.nick_name}
                                    sx={{ width: 30, height: 30 }}
                                />
                            </mui.Box>
                        </>
                    ) : (
                        <>
                            <mui.Link href={prevPage} className="inline-text" sx={{ color: '#FFFFFF' }}>
                                <ArrowBack color="default" /> Back
                            </mui.Link>
                            <mui.Box sx={{ flexGrow: 1 }} />
                            <mui.Box className="inline-text">
                                <mui.Typography noWrap component="div" sx={{ mr: 1 }}>
                                    {config.siteTagline}
                                </mui.Typography>
                                <mui.Avatar
                                    alt={config.siteTitle}
                                    src={logo}
                                    sx={{ width: 30, height: 30 }}
                                />
                            </mui.Box>
                        </>
                    )}
                </mui.Toolbar>
            </mui.Container>
        </mui.AppBar>
    )
}
export default AppHeader;
