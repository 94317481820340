import objectAssign from "object-assign";
import * as actionTypes from '../../constants/actionTypes';
import initialState from "./initialState";

export default function CourtUserReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_USER_SUCCESS:
            return objectAssign({}, state, {
                user: {
                    ...state.user,
                    loading: false,
                    users: action.data
                }
            });
        default:
            return state;
    }
}