import React, { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import * as mui from '@mui/material';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import AppHeader from "../AppHeader";
import LocalStorage from "../../storage/LocalStorage";

const Users = (props) => {
    const { tourId } = useParams();
    const navigate = useNavigate();
    const userData = props.data.user;
    /*** Get data on page load ***/
    useEffect(() => {
        props.loadUsers({ tour_id: tourId });
    }, []);
    const goToLogin = (user) => {
        LocalStorage.setUser(user);
        LocalStorage.setUserId(user.id);
        navigate('/login');
    }
    /*** Render output ***/
    return (
        <>
            <AppHeader page="/tournament" />
            <mui.Container component="main" maxWidth="xs">
                {userData.users.length > 0 ? (
                    Object.values(userData.users).map((item, key) => {
                        return (
                            <mui.Link key={key} onClick={() => goToLogin(item)} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                <mui.Paper className="paper-hover" sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                                    <mui.Grid container wrap="nowrap" spacing={2}>
                                        <mui.Grid item xs={2}>
                                            <mui.Avatar src={item.profile_image} alt={item.nick_name} sx={{ width: '50px', height: '50px', textAlign: 'center' }} />
                                        </mui.Grid>
                                        <mui.Grid item xs={8}>
                                            <mui.Typography variant="body2" sx={{ mt: 2, lineHeight: 1.2 }}>
                                                {item.first_name + ' ' + item.last_name}
                                            </mui.Typography>
                                        </mui.Grid>
                                        <mui.Grid item xs={2}>
                                            <mui.Typography variant="body2" sx={{ mt: 2, lineHeight: 1.2 }}>
                                                <DoubleArrow />
                                            </mui.Typography>
                                        </mui.Grid>
                                    </mui.Grid>
                                </mui.Paper>
                            </mui.Link>
                        );
                    })
                ) : (
                    <mui.Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                        <mui.Grid container wrap="nowrap" spacing={2}>
                            <mui.Grid item>
                                <mui.Typography align="center">
                                    {userData.loading ? userData.loadingText : 'No Records Found'}
                                </mui.Typography>
                            </mui.Grid>
                        </mui.Grid>
                    </mui.Paper>
                )}
            </mui.Container>
        </>
    );
}
export default Users;