import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import * as mui from '@mui/material';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import AppHeader from "../AppHeader";
import LocalStorage from '../../storage/LocalStorage';
import './styles.css';

const Courts = (props) => {
    const navigate = useNavigate();
    const courtData = props.data.court;
    //console.log(courtData);
    /*** Get data on page load ***/
    useEffect(() => {
        props.loadCourts();
    }, []);
    /*** Go to match list page ***/
    const goToNextPage = (item) => {
        LocalStorage.setSelectedCourt(item);
        navigate(`/match-list/${item.id}`);
    }
    /*** Render Output ***/
    return (
        <>
            <AppHeader page="/tournament" />
            <mui.Container component="main" maxWidth="xs">
                {courtData.courts.length > 0 ? (
                    Object.values(courtData.courts).map((item, key) => {
                        return (
                            <mui.Link key={key} onClick={() => goToNextPage(item)} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                <mui.Paper className="paper-hover" sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                                    <mui.Grid container wrap="nowrap" spacing={2}>
                                        <mui.Grid item xs={2}>
                                            <mui.Avatar variant="square" src={item.court_image} alt={item.court_code} className="avatar-image" sx={{ width: '100%' }} />
                                        </mui.Grid>
                                        <mui.Grid item xs={8}>
                                            <mui.Typography variant="body2" sx={{ mt: 1, lineHeight: 1.2 }}>
                                                {item.court_name}
                                            </mui.Typography>
                                        </mui.Grid>
                                        <mui.Grid item xs={2}>
                                            <mui.Typography variant="body2" sx={{ mt: 2, lineHeight: 1.2 }}>
                                                <DoubleArrow />
                                            </mui.Typography>
                                        </mui.Grid>
                                    </mui.Grid>
                                </mui.Paper>
                            </mui.Link>
                        );
                    })
                ) : (
                    <mui.Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                        <mui.Grid container wrap="nowrap" spacing={2}>
                            <mui.Grid item>
                                <mui.Typography align="center">
                                    {courtData.loading ? courtData.loadingText : 'No Records Found'}
                                </mui.Typography>
                            </mui.Grid>
                        </mui.Grid>
                    </mui.Paper>
                )}
            </mui.Container>
        </>
    );
}
export default Courts;