/**
 * Root Reducers: Combine the all reducers into a single file
 */
import { combineReducers } from 'redux';
import ApiReducers from './ApiReducers';
import TournamentReducer from './TournamentReducer';
import CourtReducer from './CourtReducer';
import UserReducer from './UserReducer';
import LoginReducers from './LoginReducers';
import MatchListReducers from './MatchLIstReducers';
import MatchScoreReducer from './MatchScoreReducer';

export default combineReducers({
    default: ApiReducers,
    tournament: TournamentReducer,
    court: CourtReducer,
    user: UserReducer,
    login: LoginReducers,
    matchList: MatchListReducers,
    matchScore: MatchScoreReducer,
});