import Api from '../../api/Api';
import * as config from '../../constants/config';
import * as actionTypes from "../../constants/actionTypes";
import * as apiActions from '../actions/ApiActions';

/**
 * synchronous action creator
 */
export const loadTournamentFitlers = (params) => {
    return async (dispatch) => {
        try {
            dispatch(apiActions.showLoader());
            await Promise.all([
                Api.getData('tournament-filters', { enviorment: config.enviornment }),
                Api.getData('tournament-list', { enviorment: config.enviornment, year: 0, quarter: 0 }),
            ]).then((result) => {
                dispatch(apiActions.hideLoader());
                result.map((apiItem, key) => {
                    let response = apiItem.data;
                    if (key === 0) {//Set filters
                        dispatch(setFilters(response.data));
                    } else {//set Tournament
                        dispatch(fetchTournaments(response.data));
                    }
                });
            }).catch((error) => {
                dispatch(apiActions.hideLoader());
                throw error;
            });
        } catch (error) {
            throw error;
        }
    }
}
export const loadTournaments = (params) => {
    return async (dispatch) => {
        try {
            dispatch(apiActions.showLoader());
            await Api.getData('tournament-list', { enviorment: config.enviornment, year: params.year, quarter: params.quarter }).then((result) => {
                let response = result.data;
                dispatch(apiActions.hideLoader());
                if (response.success) {
                    dispatch(fetchTournaments(response.data));
                } else {
                    dispatch(fetchTournaments([]));
                }
            }).catch((error) => {
                dispatch(apiActions.hideLoader());
                throw error;
            });
        } catch (error) {
            throw error;
        }
    }
};
const setFilters = (response) => {
    return {
        type: actionTypes.SET_TOURNAMENT_FILTER,
        data: response
    }
}
const fetchTournaments = (response) => {
    return {
        type: actionTypes.FETCH_TOURNAMENT_SUCCESS,
        data: response
    }
}
