import objectAssign from 'object-assign';
import * as actionTypes from '../../constants/actionTypes';
import initialState from './initialState';

export default function TournamentReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_TOURNAMENT_FILTER:
            return objectAssign({}, state, {
                tournament: {
                    ...state.tournament,
                    loading: false,
                    year: action.data.default_year,
                    years: action.data.year,
                    quarter: action.data.default_quarter,
                    quarters: action.data.quarter,
                }
            });
        case actionTypes.FETCH_TOURNAMENT_SUCCESS:
            return objectAssign({}, state, {
                tournament: {
                    ...state.tournament,
                    loading: false,
                    year: action.data.default_year,
                    quarter: action.data.default_quarter,
                    tournaments: action.data.tournaments,
                }
            });
        default:
            return state;
    }
}