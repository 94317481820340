import React from "react";
import * as mui from '@mui/material';
import RadioButton from "./RadioButton";

const WinnerPlayer = (props) => {
    const { match, score, changeScore } = props;
    const teamOneName = match.player_one_name;
    const teamTwoName = match.player_two_name;
    /*** Render output ***/
    return (
        <mui.FormControl component="fieldset" size="small">
            <mui.RadioGroup
                name="winner_id"
                aria-label="winner_id"
                defaultValue={score.winner_id}
                value={score.winner_id}
                onChange={(e) => changeScore(e, score)}
            >
                <mui.FormControlLabel value={match.player_one_id} control={<RadioButton />} label={teamOneName} />
                <mui.FormControlLabel value={match.player_two_id} control={<RadioButton />} label={teamTwoName} />
                <mui.FormControlLabel value="unknown" control={<RadioButton />} label="Unknown" />
            </mui.RadioGroup>
        </mui.FormControl>
    );
}

export default WinnerPlayer;