import React from "react";
import * as mui from '@mui/material';
import * as config from '../../constants/config';
import logo from '../../logo.png';
import './Styles.css';

const Home = (props) => {
    /*** Render output ***/
    return (
        <mui.Container component="main" maxWidth="xs">
            <div className="app-container text-center">
                <img src={logo} alt="logo" className="app-logo" />
                <mui.Typography variant="h5" component="h3" sx={{ mt: 2, fontWeight: 'bold' }}>
                    {config.siteName}
                </mui.Typography>
                <mui.Typography variant="h6" component="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
                    Match Score
                </mui.Typography>
                <mui.Button color="success" variant="outlined" href="/tournament">Continue</mui.Button>
            </div>
        </mui.Container>
    );
}

export default Home;