const initialState = {
    isLoading: false,
    loaderColor: '#1976d2',
    user: {
        loading: true,
        loadingText: 'Please wait, loading...',
        isLoggedIn: false,
        loginValidate: {
            error: false,
            message: ''
        },
        loginForm: {
            device_type: 'Web',
            tour_id: 0,
            primary_id: 0,
            auth_pin: 0,
            rememberMe: false,
        },
        user: {},
        users: [],
    },
    tournament: {
        loading: true,
        loadingText: 'Please wait, loading...',
        year: 0,
        quarter: 0,
        years: [{ label: 'Year', value: 0 }],
        quarters: [{ label: 'Quarter', value: 0 }],
        tournaments: [],
        tournament: {}
    },
    court: {
        loading: true,
        loadingText: 'Please wait, loading...',
        courts: []
    },
    matchList: {
        loading: true,
        loadingText: 'Please wait, loading...',
        tournament: {},
        category_id: 0,
        categoryArr: [],
        gender: '',
        genderArr: [],
        rounds: '',
        roundAndGroup: [],
        matches: []
    },
    matchScore: {
        loading: true,
        loadingText: 'Please wait, loading...',
        isUpdateScore: false,
        match: {},
        validate: {
            error: true,
            message: 'Please enter the required fields?',
        },
        score: {
            match_id: 0,
            table_type: 0,
            score_player1: ['', '', ''],
            score_player2: ['', '', ''],
            score_tiebrak: ['', '', ''],
            score_p1s1: '',
            score_p1s2: '',
            score_p1s3: '',
            score_p2s1: '',
            score_p2s2: '',
            score_p2s3: '',
            score_tbs1: '',
            score_tbs2: '',
            score_tbs3: '',
            status_id: 0,
            score_format: 0,
            chair_umpire_id: 0,
            court_id: 0,
            winner_id: 'unknown',
        },
    }
};
export default initialState;