import Api from "../../api/Api";
import * as apiActions from '../actions/ApiActions';
import * as config from '../../constants/config';
import * as actionTypes from '../../constants/actionTypes';
import LocalStorage from "../../storage/LocalStorage";

/**
 * synchronous action creator
 */
export const loadUsers = (params) => {
    return async (dispatch) => {
        try {
            dispatch(apiActions.showLoader());
            await Api.getData('tournament-chair-umpires', { enviorment: config.enviornment, tour_id: params.tour_id }).then((result) => {
                let response = result.data;
                if (response.success) {
                    LocalStorage.setTourId(params.tour_id);
                    dispatch(apiActions.hideLoader());
                    dispatch(fetchUsers(response.data));
                }
            }).catch((error) => {
                dispatch(apiActions.hideLoader());
                throw error;
            });
        } catch (error) {
            throw error;
        }
    }
}

export const fetchUsers = (response) => {
    return {
        type: actionTypes.FETCH_USER_SUCCESS,
        data: response
    }
}

