import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import objectAssign from 'object-assign';
import * as mui from '@mui/material';
import AppHeader from "../AppHeader";
import LocalStorage from "../../storage/LocalStorage";

const Login = (props) => {
    const userData = props.data.user;
    const tourId = LocalStorage.getTourId();
    const navigate = useNavigate();
    /*** Get data on page load ***/
    useEffect(() => {
        props.selectedUser();
    }, []);
    /*** On change ***/
    const change = (e, user) => {
        const curObj = objectAssign({}, user);
        curObj.loginForm.auth_pin = e.target.value;
        props.changeLoginForm(curObj);
    }
    /*** Handle User Login ***/
    const handleLogin = (params) => {
        if (params.auth_pin.length >= 6) {
            props.login(params);
        } else {
            props.validateLogin({
                error: true,
                message: 'Auth Pin must be 6 characters in length?'
            });
        }
    }
    /*** After Login move to Court page ***/
    if (userData.isLoggedIn) {
        navigate('/courts');
    }
    /*** Return output ***/
    return (
        <>
            <AppHeader page={`/users/` + tourId} />
            <mui.Container component="main" maxWidth="xs">
                <mui.Box>
                    <mui.Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mt={4}
                        mb={2}
                    >
                        <mui.Avatar
                            alt={userData.user.nick_name}
                            src={userData.user.profile_image}
                            sx={{
                                width: 110,
                                height: 'auto',
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderColor: '#ddd'
                            }}
                        />
                    </mui.Box>
                    <mui.Typography textAlign="center" mb={3}>
                        {userData.user.first_name + ' ' + userData.user.last_name}
                    </mui.Typography>
                    <mui.Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        textAlign="center"
                    >
                        <mui.TextField
                            type="password"
                            id="auth_pin"
                            label="Auth Pin"
                            variant="outlined"
                            defaultValue={(parseInt(userData.loginForm.auth_pin) > 0) ? userData.loginForm.auth_pin : ''}
                            onChange={(e) => change(e, props.data.user)}
                            sx={{ width: '80%', mx: 'auto', mb: 2 }}
                            error={userData.loginValidate.error}
                            helperText={userData.loginValidate.message}
                        />
                        <mui.Button onClick={() => handleLogin(userData.loginForm)} variant="outlined" sx={{ width: '100px', mx: "auto" }}>Login</mui.Button>
                    </mui.Box>
                </mui.Box>
            </mui.Container>
        </>
    );
}

export default Login;