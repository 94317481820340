import Api from "../../api/Api";
import * as ajaxRequest from '../../api/ajaxCallCreators';
import * as apiActions from '../actions/ApiActions';
import * as actionTypes from '../../constants/actionTypes';
import LocalStorage from "../../storage/LocalStorage";
import toastr from "toastr";

/**
 * synchronous action creator
 */
export const loadMatchData = () => {
    return async (dispatch) => {
        try {
            dispatch(apiActions.showLoader());
            await Api.getData('status-score-format', {
                api_token: LocalStorage.getAccessToken()
            }).then((result) => {
                dispatch(apiActions.hideLoader());
                let response = result.data;
                if (response.success) {
                    dispatch(fetchMatchStorage(response.data));
                }
            }).catch((error) => {
                dispatch(apiActions.hideLoader());
                throw error;
            });
        } catch (error) {
            throw error;
        }
    }
}

export const saveMatchScore = (data) => {
    return async (dispatch) => {
        try {
            dispatch(apiActions.showLoader());
            data['api_token'] = LocalStorage.getAccessToken();
            await ajaxRequest.post('draw-save-match-score', data, 'score', false).then((response) => {
                dispatch(apiActions.hideLoader());
                if (response.success) {
                    dispatch(resetMatchScore());
                    toastr.success(response.message);
                } else {
                    toastr.error(response.message);
                }
            }).catch((error) => {
                dispatch(apiActions.hideLoader());
                throw error;
            });
        } catch (error) {
            throw error;
        }
    }
}

const fetchMatchStorage = (response) => {
    return {
        type: actionTypes.FETCH_MATCH_STORAGE,
        data: response
    }
}

export const resetMatchScore = () => {
    return {
        type: actionTypes.RESET_MATCH_SCORE,
    }
}

export const changMatchScore = (data) => {
    return {
        type: actionTypes.CHANGE_MATCH_SCORE,
        data: data
    }
}