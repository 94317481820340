import { connect } from "react-redux";
import propTypes from 'prop-types';
import MatchLIst from "../components/MatchList/MatchLIst";
import * as actionCreators from '../services/actions/MatchLIstActions';

/**
 * Map State to Props
 */
const mapStateToProps = (stete) => ({
    data: stete.matchList
});

MatchLIst.propTypes = {
    data: propTypes.object.isRequired
};

export default connect(mapStateToProps, actionCreators)(MatchLIst);