import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import LocalStorage from "../storage/LocalStorage";

const PrivateRoute = () => {
    /**
     * Determine if authorized from Access Token
     */
    const isLoggedIn = LocalStorage.isLoggedIn();
    /**
     * If authorized, return an outlet that will render child elements
     * If not, return element that will navigate to home page
     */
    return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;