import Api from '../../api/Api';
import * as config from '../../constants/config';
import * as actionTypes from "../../constants/actionTypes";
import * as apiActions from '../actions/ApiActions';
import LocalStorage from '../../storage/LocalStorage';

const getMatches = async (params) => {
    const { category_id, gender, rounds } = params;
    const dataPromise = await Api.getData('tournament-match-list', {
        enviorment: config.enviornment,
        tour_id: LocalStorage.getTourId(),
        api_token: LocalStorage.getAccessToken(),
        user_id: LocalStorage.getUserId(),
        category_id: category_id,
        gender: gender,
        round: rounds
    }).then((result) => result.data).catch((error) => {
        throw error;
    });
    return dataPromise;
}
/**
 * synchronous action creator
 */
export const loadTournamentDetails = () => {
    return async (dispatch) => {
        try {
            dispatch(apiActions.showLoader());
            Api.getData('tournament-details', {
                enviorment: config.enviornment,
                id: LocalStorage.getTourId(),
                api_token: LocalStorage.getAccessToken()
            }).then((result) => {
                dispatch(apiActions.hideLoader());
                let response = result.data;
                if (response.success) {
                    dispatch(fetchTournamentDetails(response.data));
                    /*** Get match list data ***/
                    getMatches(response.data).then((res) => {
                        if (res.success) {
                            dispatch(fetchMatchList(res.data));
                        }
                    });
                }
            }).catch((error) => {
                dispatch(apiActions.hideLoader());
                throw error;
            })
        } catch (error) {
            throw error;
        }
    };
}
export const loadMatchList = (params) => {
    return (dispatch) => {
        dispatch(apiActions.showLoader());
        getMatches(params).then((res) => {
            dispatch(apiActions.hideLoader());
            if (res.success) {
                dispatch(fetchMatchList(res.data));
            } else {
                dispatch(fetchMatchList([]));
            }
        }).catch((error) => {
            dispatch(apiActions.hideLoader());
            throw error;
        });
    }
}
export const fetchTournamentDetails = (respone) => {
    return {
        type: actionTypes.FETCH_TOURNAMENT_DETAILS,
        data: respone
    }
}
export const fetchMatchList = (respone) => {
    return {
        type: actionTypes.FETCH_MATCHLIST_SUCCESS,
        data: respone
    }
}
export const changeGender = (gender) => {
    return {
        type: actionTypes.CHANGE_GENDER,
        data: gender
    }
}
export const changeCategory = (category_id) => {
    return {
        type: actionTypes.CHANGE_CATEGORY,
        data: category_id
    }
}
export const changeRound = (round) => {
    return {
        type: actionTypes.CHANGE_ROUND,
        data: round
    }
}