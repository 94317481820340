import React from 'react';
import AppRoutes from './components/AppRoutes';
import CssBaseline from '@mui/material/CssBaseline';
import AppLoader from './components/AppLoader';

function App() {
  return (
    <React.StrictMode>
      <AppLoader />
      <CssBaseline />
      <AppRoutes />
    </React.StrictMode>
  );
}

export default App;
