import React from "react";
import { connect } from 'react-redux';
import ScaleLoader from 'react-spinners/ScaleLoader';
import * as actionCreators from '../services/actions/ApiActions';
import '../App.css';

const AppLoader = (props) => {
    if (props.data.isLoading == false) return null;
    return (
        <div className="inline-text loader">
            <ScaleLoader color={props.data.loaderColor} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    data: state.default
});

export default connect(mapStateToProps, actionCreators)(AppLoader);