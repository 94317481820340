import React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import * as mui from '@mui/material';
import AppHeader from "../AppHeader";
import './styles.css';
import LocalStorage from "../../storage/LocalStorage";
import toastr from "toastr";

const MatchLIst = (props) => {
    const matchList = props.data.matchList;
    const {
        tournament,
        category_id,
        categoryArr,
        gender,
        genderArr,
        rounds,
        roundAndGroup,
        matches
    } = matchList;
    const { courtId } = useParams();
    const navigate = useNavigate();
    /*** On page load ***/
    React.useEffect(() => {
        LocalStorage.setCourtId(courtId);
        props.loadTournamentDetails();
    }, []);
    /*** Set Category Dropdown data ***/
    let categoryOption = [];
    if (categoryArr.hasOwnProperty(gender)) {
        categoryOption = categoryArr[gender];
    }

    /*** Set Round Dropdown data ***/
    let roundOption = [];
    if (roundAndGroup.hasOwnProperty(gender)) {
        let roundByCategory = roundAndGroup[gender];
        if (roundByCategory.hasOwnProperty(category_id)) {
            roundOption = roundByCategory[category_id];
        }
    }
    const handleGenderChange = (event) => {
        let curGender = event.target.value;
        props.changeGender(curGender);
        props.loadMatchList({
            category_id: category_id,
            gender: curGender,
            rounds: rounds
        });
    }
    const handleCategoryChange = (event) => {
        let curCategory = event.target.value;
        props.changeCategory(curCategory);
        props.loadMatchList({
            category_id: curCategory,
            gender: gender,
            rounds: rounds
        });
    }
    const handleRoundsChange = (event) => {
        let curRounds = event.target.value;
        props.changeRound(curRounds);
        props.loadMatchList({
            category_id: category_id,
            gender: gender,
            rounds: curRounds
        });
    }
    /*** Going to match score page ***/
    const goToMatchScore = (match) => {
        if (match.player_one_id !== null && match.player_two_id !== null) {
            LocalStorage.setCurrentMatch(match);
            navigate('/match-score');
        } else {
            toastr.error('Please complete the previous match, before proceeding', 'Error');
        }
    }
    /*** Render Output ***/
    return (
        <>
            <AppHeader page="courts" />
            {matchList.loading ? (
                <mui.Container component="main" maxWidth="xs">
                    <mui.Box>{matchList.loadingText}</mui.Box>
                </mui.Container>
            ) : (
                <mui.Container component="main" maxWidth="xs">
                    <mui.Box fontSize="90%" className="text-single-line" sx={{ textAlign: 'center' }}>
                        {tournament.weekof_title}, {tournament.name}
                    </mui.Box>
                    <mui.Box sx={{ mt: 2, display: 'flex' }}>
                        <mui.FormControl sx={{ minWidth: '29%' }}>
                            <mui.InputLabel id="filterGender">Gender</mui.InputLabel>
                            <mui.Select
                                id="gender"
                                labelId="filterGender"
                                label="Gender"
                                value={gender}
                                defaultValue={gender}
                                onChange={handleGenderChange}
                                size="small"
                            >
                                {Object.values(genderArr).map((item, key) => {
                                    return <mui.MenuItem key={key} value={item.value}>{item.label}</mui.MenuItem>;
                                })}
                            </mui.Select>
                        </mui.FormControl>
                        <mui.Box sx={{ flexGrow: 1 }} />
                        <mui.FormControl sx={{ minWidth: '39%' }}>
                            <mui.InputLabel id="filterCategory">Category</mui.InputLabel>
                            <mui.Select
                                id="category"
                                labelId="filterCategory"
                                label="Category"
                                value={category_id}
                                defaultValue={category_id}
                                onChange={handleCategoryChange}
                                size="small"
                            >
                                {(categoryOption.length > 0) ?
                                    categoryOption.map((item, key) => {
                                        return <mui.MenuItem key={key} value={parseInt(item.id)}>{item.category_name}</mui.MenuItem>;
                                    })
                                    :
                                    <mui.MenuItem value="0">None</mui.MenuItem>
                                }
                            </mui.Select>
                        </mui.FormControl>
                        <mui.Box sx={{ flexGrow: 1 }} />
                        <mui.FormControl sx={{ minWidth: '29%' }}>
                            <mui.InputLabel id="filterRound">Rounds</mui.InputLabel>
                            <mui.Select
                                id="rounds"
                                labelId="filterRound"
                                label="Rounds"
                                value={rounds}
                                defaultValue={rounds}
                                onChange={handleRoundsChange}
                                size="small"
                            >
                                {(roundOption.length > 0) ?
                                    roundOption.map((item, key) => {
                                        return <mui.MenuItem key={key} value={item.keys}>{item.vals}</mui.MenuItem>;
                                    })
                                    :
                                    <mui.MenuItem value="0">None</mui.MenuItem>
                                }
                            </mui.Select>
                        </mui.FormControl>
                    </mui.Box>
                    {matches.length > 0 ? (
                        Object.values(matches).map((item, key) => {
                            return (
                                <mui.Link key={key} onClick={() => goToMatchScore(item)} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    <mui.Paper className="paper-hover" sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                                        {/* Team One */}
                                        <mui.Grid container wrap="nowrap" spacing={2}>
                                            <mui.Grid item>
                                                <div className="inline-text">
                                                    <mui.Avatar src={item.player_one_image} alt={item.player_one_first_name} sx={{ width: 40, height: 40 }} />
                                                    {item.player_one_partner_id != null ?
                                                        <mui.Avatar src={item.partner_one_image} alt={item.partner_one_first_name} sx={{ width: 40, height: 40 }} />
                                                        : null}
                                                </div>
                                            </mui.Grid>
                                            <mui.Grid item className="vertical-center">
                                                <mui.Typography variant="body1" sx={{ m: 0, lineHeight: 1.2 }}>
                                                    {item.player_one_name}
                                                </mui.Typography>
                                                {item.player_one_partner_id != null ?
                                                    <mui.Typography variant="body1" sx={{ m: 0, lineHeight: 1.2 }}>
                                                        {item.partner_one_name}
                                                    </mui.Typography>
                                                    : null}
                                            </mui.Grid>
                                        </mui.Grid>
                                        <mui.Box className="line-vs">
                                            <mui.Typography variant="body1" sx={{ m: 0, fontSize: '80%' }}>Vs</mui.Typography>
                                        </mui.Box>
                                        {/* Team Two */}
                                        <mui.Grid container wrap="nowrap" spacing={2}>
                                            <mui.Grid item>
                                                <div className="inline-text">
                                                    <mui.Avatar src={item.player_two_image} alt={item.player_two_first_name} sx={{ width: 40, height: 40 }} />
                                                    {item.player_two_partner_id != null ?
                                                        <mui.Avatar src={item.partner_two_image} alt={item.partner_two_first_name} sx={{ width: 40, height: 40 }} />
                                                        : null}
                                                </div>
                                            </mui.Grid>
                                            <mui.Grid item className="vertical-center">
                                                <mui.Typography variant="body1" sx={{ m: 0, lineHeight: 1.2 }}>
                                                    {item.player_two_name}
                                                </mui.Typography>
                                                {item.player_two_partner_id != null ?
                                                    <mui.Typography variant="body1" sx={{ m: 0, lineHeight: 1.2 }}>
                                                        {item.partner_two_name}
                                                    </mui.Typography>
                                                    : null}
                                            </mui.Grid>
                                        </mui.Grid>
                                    </mui.Paper>
                                </mui.Link>
                            );
                        })
                    ) : (
                        <mui.Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                            <mui.Grid container wrap="nowrap" spacing={2}>
                                <mui.Grid item xs={12}>
                                    <mui.Typography align="center">
                                        {matchList.loading ? matchList.loadingText : 'No Records Found'}
                                    </mui.Typography>
                                </mui.Grid>
                            </mui.Grid>
                        </mui.Paper>
                    )}
                </mui.Container>
            )}
        </>
    );
}

export default MatchLIst;