import objectAssign from 'object-assign';
import * as actionTypes from '../../constants/actionTypes';
import LocalStorage from '../../storage/LocalStorage';
import initialState from './initialState';

export default function MatchScoreReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_MATCH_STORAGE:
            /*** Get Current Match ***/
            const currentMatch = LocalStorage.getCurrentMatch();
            return objectAssign({}, state, {
                matchScore: {
                    ...state.matchScore,
                    loading: false,
                    tournament: LocalStorage.getSelectedTournament(),
                    match: currentMatch,
                    match_status: action.data.status,
                    court: LocalStorage.getSelectedCourt(),
                    user: LocalStorage.getLoggedInUser(),
                    scoring_format: action.data.scoring_format,
                    score: {
                        ...state.matchScore.score,
                        match_id: currentMatch.id,
                        table_type: currentMatch.table_type,
                        court_id: LocalStorage.getCourtId(),
                        chair_umpire_id: LocalStorage.getUserId(),
                    }
                }
            });
        case actionTypes.CHANGE_MATCH_SCORE:
            let inputError = true;
            let errorMessage = 'Please enter the required fields?';
            if (action.data.winner_id !== 'unknown' && parseInt(action.data.score_format) > 0) {
                inputError = false;
                errorMessage = 'Valid Input';
            }
            return objectAssign({}, state, {
                matchScore: {
                    ...state.matchScore,
                    validate: {
                        error: inputError,
                        message: errorMessage
                    },
                    score: action.data,
                }
            });
        case actionTypes.RESET_MATCH_SCORE:
            return objectAssign({}, state, {
                matchScore: {
                    ...state.matchScore,
                    isUpdateScore: false,
                    score: {
                        match_id: 0,
                        table_type: 0,
                        score_player1: ['', '', ''],
                        score_player2: ['', '', ''],
                        score_tiebrak: ['', '', ''],
                        score_p1s1: '',
                        score_p1s2: '',
                        score_p1s3: '',
                        score_p2s1: '',
                        score_p2s2: '',
                        score_p2s3: '',
                        score_tbs1: '',
                        score_tbs2: '',
                        score_tbs3: '',
                        status_id: 0,
                        score_format: 0,
                        chair_umpire_id: 0,
                        court_id: 0,
                        winner_id: 'unknown',
                    },
                }
            });
        default:
            return state;
    }
}