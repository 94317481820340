//App
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
//Tournament
export const SET_TOURNAMENT_FILTER = "SET_TOURNAMENT_FILTER";
export const FETCH_TOURNAMENT_SUCCESS = "FETCH_TOURNAMENT_SUCCESS";
export const FETCH_TOURNAMENT_DETAILS = "FETCH_TOURNAMENT_DETAILS";
//Court
export const FETCH_COURT_SUCCESS = "FETCH_COURT_SUCCESS";
//Users
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const SELECT_USER_SUCCESS = "SELECT_USER_SUCCESS";
//Login
export const CHANGE_LOGIN_FORM = "CHANGE_LOGIN_FORM";
export const VALIDATE_LOGIN_FORM = "VALIDATE_LOGIN_FORM";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
//Match List
export const CHANGE_GENDER = "CHANGE_GENDER";
export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const CHANGE_ROUND = "CHANGE_ROUND";
export const FETCH_MATCHLIST_SUCCESS = "FETCH_MATCHLIST_SUCCESS";
//Match score
export const FETCH_MATCH_STORAGE = "FETCH_MATCH_STORAGE";
export const RESET_MATCH_SCORE = "RESET_MATCH_SCORE";
export const CHANGE_MATCH_SCORE = "CHANGE_MATCH_SCORE";
export const SAVE_MATCH_SCORE = "SAVE_MATCH_SCORE";