import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import * as mui from '@mui/material';
import './Styles.css';
import AppHeader from "../AppHeader";
import LocalStorage from "../../storage/LocalStorage";

const Tournament = (props) => {
    const navigate = useNavigate();
    const tourData = props.data;
    /*** Get data on page load ***/
    useEffect(() => {
        props.loadTournamentFitlers({ year: 0, quarter: 0 });
    }, []);
    /*** Filter Year Handler ***/
    const yearHandleChange = (event) => {
        let curYear = event.target.value;
        props.loadTournaments({ year: curYear, quarter: tourData.quarter });
    }
    /*** Filter Quarterr Handler ***/
    const quarterHandleChange = (event) => {
        let curQuarter = event.target.value;
        props.loadTournaments({ year: tourData.year, quarter: curQuarter });
    }
    /*** Go to user page(chaire umpire) ***/
    const goToUserPage = (item) => {
        LocalStorage.setSelectedTournament(item);
        navigate(`/users/${item.id}`);
    }
    /*** Render output ***/
    return (
        <>
            <AppHeader page="/" />
            <mui.Container component="main" maxWidth="xs">
                <mui.Box sx={{ mt: 2, display: 'flex' }}>
                    <mui.FormControl sx={{ minWidth: '40%' }}>
                        <mui.InputLabel id="filterYear">Year</mui.InputLabel>
                        <mui.Select
                            id="year"
                            labelId="filterYear"
                            label="Year"
                            size="small"
                            value={tourData.year}
                            defaultValue={tourData.year}
                            onChange={yearHandleChange}
                        >
                            {Object.values(tourData.years).map((item, key) => {
                                return <mui.MenuItem key={key} value={parseInt(item.value)}>{item.label}</mui.MenuItem>;
                            })}
                        </mui.Select>
                    </mui.FormControl>
                    <mui.Box sx={{ flexGrow: 1 }} />
                    <mui.FormControl sx={{ minWidth: '40%' }}>
                        <mui.InputLabel id="filterQuarter">Quarter</mui.InputLabel>
                        <mui.Select
                            id="quarter"
                            labelId="filterQuarter"
                            label="Quarter"
                            size="small"
                            value={tourData.quarter}
                            defaultValue={tourData.quarter}
                            onChange={quarterHandleChange}
                        >
                            {Object.values(tourData.quarters).map((item, key) => {
                                return <mui.MenuItem key={key} value={parseInt(item.value)}>{item.label}</mui.MenuItem>;
                            })}
                        </mui.Select>
                    </mui.FormControl>
                </mui.Box>
                {tourData.tournaments.length > 0 ? (
                    Object.values(tourData.tournaments).map((item, key) => {
                        return (
                            <mui.Link key={key} onClick={() => goToUserPage(item)} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                <mui.Paper className="paper-hover" sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                                    <mui.Grid container wrap="nowrap" spacing={2}>
                                        <mui.Grid item>
                                            <mui.Avatar sx={{ width: '60px', height: '60px', textAlign: 'center' }}>
                                                {item.weekof_day}<br />{item.weekof_month}
                                            </mui.Avatar>
                                        </mui.Grid>
                                        <mui.Grid item xs>
                                            <mui.Typography sx={{ lineHeight: 1.2 }}>
                                                {item.name}
                                            </mui.Typography>
                                            <mui.Typography variant="subtitle2" sx={{ fontSize: '75%' }}>
                                                Event Date - {item.start_date} - {item.end_date}
                                            </mui.Typography>
                                        </mui.Grid>
                                    </mui.Grid>
                                </mui.Paper>
                            </mui.Link>
                        );
                    })
                ) : (
                    <mui.Paper sx={{ maxWidth: 400, my: 1, mx: 'auto', p: 2 }}>
                        <mui.Grid container wrap="nowrap" spacing={2}>
                            <mui.Grid item xs={12}>
                                <mui.Typography align="center">
                                    {tourData.loading ? tourData.loadingText : 'No Records Found'}
                                </mui.Typography>
                            </mui.Grid>
                        </mui.Grid>
                    </mui.Paper>
                )}

            </mui.Container >
        </>
    );
}

export default Tournament;