import React from "react";
import { useNavigate } from 'react-router-dom';
import * as mui from '@mui/material';
import {
    Menu,
    PowerSettingsNew,
    Input
} from "@mui/icons-material";
import * as config from '../constants/config';
import LocalStorage from "../storage/LocalStorage";
import logo from '../logo.png';

const AppDrawer = () => {
    const [state, setState] = React.useState({ left: false });
    const navigate = useNavigate();
    const user = LocalStorage.getLoggedInUser() || {};
    const drawerPosition = 'left';
    /*** Toggle Drawer Menu ***/
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    /*** User Logout ***/
    const logout = () => {
        LocalStorage.logout();
        navigate('/');
    }
    /*** Generate menu List ***/
    const list = (anchor) => (
        <mui.Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 200 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <mui.Typography
                align="center"
                className="inline-text"
                sx={{ color: '#FFFFFF', backgroundColor: '#1976d2', py: 2 }}
            >
                <mui.Avatar
                    alt={config.siteTitle}
                    src={logo}
                    sx={{ width: 30, height: 30, mr: 1 }}
                />
                {config.siteTagline}
            </mui.Typography>
            <mui.Box component="div" sx={{ my: 2 }}>
                <mui.Avatar
                    alt={user.nick_name}
                    src={user.profile_image}
                    sx={{ width: 90, height: 90, mx: 'auto' }}
                />
                <mui.Typography
                    variant="subtitle1"
                    align="center"
                >
                    {user.first_name + ' ' + user.last_name}
                </mui.Typography>
            </mui.Box>
            <mui.Divider />
            <mui.List>
                <mui.ListItem button component="a" href="/courts">
                    <mui.ListItemIcon><Input /></mui.ListItemIcon>
                    <mui.ListItemText primary="Court List" />
                </mui.ListItem>
                <mui.Divider />
                <mui.ListItem button onClick={() => logout()}>
                    <mui.ListItemIcon><PowerSettingsNew /></mui.ListItemIcon>
                    <mui.ListItemText primary="Logout" />
                </mui.ListItem>
            </mui.List>
        </mui.Box>
    );
    /*** Render output ***/
    return (
        <React.Fragment>
            <mui.Button onClick={toggleDrawer(drawerPosition, true)} sx={{ color: '#FFFFFF', minWidth: 40 }}>
                <Menu />
            </mui.Button>
            <mui.Drawer
                anchor={drawerPosition}
                open={state[drawerPosition]}
                onClose={toggleDrawer(drawerPosition, false)}
            >
                {list(drawerPosition)}
            </mui.Drawer>
        </React.Fragment>
    );
}

export default AppDrawer;