import React from "react";
import { useNavigate } from 'react-router-dom';
import * as mui from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowBack from '@mui/icons-material/ArrowBack';
import toastr from 'toastr';
import AppHeader from "../AppHeader";
import './styles.css';
import WinnerPlayer from "./WinnerPlayer";
import objectAssign from "object-assign";
import LocalStorage from "../../storage/LocalStorage";

const MatchScore = (props) => {
    const {
        loading,
        loadingText,
        tournament,
        match,
        validate,
        court,
        user,
        score,
        match_status,
        scoring_format
    } = props.data;
    console.log(props.data);
    const navigate = useNavigate();
    const [courtId, setCourtId] = React.useState(LocalStorage.getCourtId());
    /*** On page load ***/
    React.useEffect(() => {
        if (props.data.isUpdateScore === true) {
            navigate(`/match-list/${courtId}`);
        }
        props.loadMatchData();
    }, [courtId]);
    /*** On Change Score ***/
    const changeScore = (e, score) => {
        const unused = ['match_id', 'table_type', 'status_id', 'score_format', 'chair_umpire_id', 'winner_id'];
        const curObj = objectAssign({}, score);
        Object.keys(curObj).map((keys) => {
            if (keys === e.target.name) {
                if (keys.includes('score_p1')) {
                    if (!isNaN(e.target.value)) {
                        let tmpArr = curObj.score_player1;
                        curObj['score_player1'] = pushScore(e.target.name, e.target.value, tmpArr);
                        curObj['winner_id'] = setWinner(curObj);
                    } else {
                        toastr.error('Please input valid score', 'Error');
                    }
                } else if (keys.includes('score_p2')) {
                    if (!isNaN(e.target.value)) {
                        let tmpArr = curObj.score_player2;
                        curObj['score_player2'] = pushScore(e.target.name, e.target.value, tmpArr);
                        curObj['winner_id'] = setWinner(curObj);
                    } else {
                        toastr.error('Please input valid score', 'Error');
                    }
                } else if (keys.includes('score_tbs')) {
                    if (!isNaN(e.target.value)) {
                        let tmpArr = curObj.score_tiebrak;
                        curObj['score_tiebrak'] = pushScore(e.target.name, e.target.value, tmpArr);
                        curObj['winner_id'] = setWinner(curObj);
                    } else {
                        toastr.error('Please input valid score', 'Error');
                    }
                } else {
                    curObj[keys] = e.target.value;
                }
            }
            return curObj;
        });
        props.changMatchScore(curObj);
    }
    const pushScore = (keys, value, tmpArr) => {
        switch (keys) {
            case 'score_p1s1':
                tmpArr[0] = value;
                return tmpArr;
                break;
            case 'score_p1s2':
                tmpArr[1] = value;
                return tmpArr;
                break;
            case 'score_p1s3':
                tmpArr[2] = value;
                return tmpArr;
                break;
            case 'score_p2s1':
                tmpArr[0] = value;
                return tmpArr;
                break;
            case 'score_p2s2':
                tmpArr[1] = value;
                return tmpArr;
                break;
            case 'score_p2s3':
                tmpArr[2] = value;
                return tmpArr;
                break;
            case 'score_tbs1':
                tmpArr[0] = value;
                return tmpArr;
                break;
            case 'score_tbs2':
                tmpArr[1] = value;
                return tmpArr;
                break;
            case 'score_tbs3':
                tmpArr[2] = value;
                return tmpArr;
                break;
            default:
                return tmpArr;
                break;
        }
    }
    /*** Established Match Winner ***/
    const setWinner = (scoreData) => {
        let winner = [
            { 'player1': match.player_one_id, 'value': 0 },
            { 'player2': match.player_two_id, 'value': 0 }
        ];
        let winnerPlayer = 'unknown';
        let scoreP1 = scoreData.score_player1;
        let scoreP2 = scoreData.score_player2;
        //Established winner
        scoreP1.map((val, i) => {
            if (parseInt(val) > parseInt(scoreP2[i])) {
                winner[0].value = winner[0].value + 1;
            } else if (parseInt(scoreP2[i]) > parseInt(val)) {
                winner[1].value = winner[1].value + 1;
            }
        });
        if (winner[0].value > winner[1].value) {
            winnerPlayer = winner[0].player1;
        } else if (winner[1].value > winner[0].value) {
            winnerPlayer = winner[1].player2;
        } else {
            winnerPlayer = 'unknown';
        }
        return winnerPlayer;
    }
    /*** Save match score into data ***/
    const submitMatchScore = (data) => {
        if (validate.error === false) {
            props.saveMatchScore(data);
        } else {
            if (data.winner_id === 'unknown') {
                toastr.error('The winner field is required?', 'Error');
            } else if (parseInt(data.score_format) === 0) {
                toastr.error('The scoring format field is required?', 'Error');
            } else {
                toastr.error(validate.message);
            }
        }
    }
    /*** Handle back button ***/
    const handleBack = () => {
        props.resetMatchScore();//Re-set match score
        navigate(`/match-list/${court.id}`);//Back to previous page
    }
    /*** Update match score then going back to court list ***/
    if (props.data.isUpdateScore) {
        window.location.reload();
    }
    /*** Render Output ***/
    return (
        <>
            <AppHeader page="/" />
            <mui.Container component="main" maxWidth="xs">
                {(loading) ? (
                    <mui.Box sx={{ textAlign: 'center' }}>
                        <p>{loadingText}</p>
                    </mui.Box>
                ) : (
                    <>
                        <mui.Box fontSize="90%" className="text-single-line" sx={{ mb: 2, textAlign: 'center' }}>
                            {tournament.weekof_date}, {tournament.name}
                        </mui.Box>
                        <mui.Table aria-label="spanning table" className="table">
                            <mui.TableHead>
                                <mui.TableRow>
                                    <mui.TableCell>Player</mui.TableCell>
                                    <mui.TableCell align="center">Set-1</mui.TableCell>
                                    <mui.TableCell align="center">Set-2</mui.TableCell>
                                    <mui.TableCell align="center">Set-3</mui.TableCell>
                                </mui.TableRow>
                            </mui.TableHead>
                            <mui.TableBody>
                                <mui.TableRow>
                                    <mui.TableCell size="small">
                                        <div className="inline-text flex-start player-name">
                                            <mui.Avatar
                                                alt={match.player_one_first_name}
                                                src={match.player_one_image}
                                                sx={{ width: 30, height: 30 }}
                                            />
                                            <mui.Typography>{match.player_one_name}</mui.Typography>
                                        </div>
                                        {(match.player_two_partner_id !== null) ?
                                            <div className="inline-text flex-start player-name">
                                                <mui.Avatar
                                                    alt={match.partner_one_first_name}
                                                    src={match.partner_one_image}
                                                    sx={{ width: 30, height: 30 }}
                                                />
                                                <mui.Typography>{match.partner_one_name}</mui.Typography>
                                            </div>
                                            : null}
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_p1s1"
                                            name="score_p1s1"
                                            label="S1"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_player1[0]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_p1s2"
                                            name="score_p1s2"
                                            label="S2"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_player1[1]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_p1s3"
                                            name="score_p1s3"
                                            label="S3"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_player1[2]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                </mui.TableRow>
                                <mui.TableRow>
                                    <mui.TableCell size="small">
                                        <div className="inline-text flex-start player-name">
                                            <mui.Avatar
                                                alt={match.player_two_first_name}
                                                src={match.player_two_image}
                                                sx={{ width: 30, height: 30 }}
                                            />
                                            <mui.Typography>{match.player_two_name}</mui.Typography>
                                        </div>
                                        {(match.player_two_partner_id !== null) ?
                                            <div className="inline-text flex-start player-name">
                                                <mui.Avatar
                                                    alt={match.partner_two_first_name}
                                                    src={match.partner_two_image}
                                                    sx={{ width: 30, height: 30 }}
                                                />
                                                <mui.Typography>{match.partner_two_name}</mui.Typography>
                                            </div>
                                            : null}
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_p2s1"
                                            name="score_p2s1"
                                            label="S1"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_player2[0]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_p2s2"
                                            name="score_p2s2"
                                            label="S2"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_player2[1]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_p2s3"
                                            name="score_p2s3"
                                            label="S3"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_player2[2]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                </mui.TableRow>
                                <mui.TableRow>
                                    <mui.TableCell align="right" size="small">Tiebrak</mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_tbs1"
                                            name="score_tbs1"
                                            label="S1"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_tiebrak[0]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_tbs2"
                                            name="score_tbs2"
                                            label="S2"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_tiebrak[1]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                    <mui.TableCell align="right" size="small">
                                        <mui.TextField
                                            id="score_tbs3"
                                            name="score_tbs3"
                                            label="S2"
                                            variant="outlined"
                                            size="small"
                                            value={score.score_tiebrak[2]}
                                            onChange={(e) => changeScore(e, score)}
                                            inputMode="numeric"
                                        />
                                    </mui.TableCell>
                                </mui.TableRow>
                            </mui.TableBody>
                        </mui.Table>
                        <mui.Table aria-label="spanning table" className="table">
                            <mui.TableBody>
                                <mui.TableRow className="row-other">
                                    <mui.TableCell size="small" align="right">Status :</mui.TableCell>
                                    <mui.TableCell size="small">
                                        <mui.Select
                                            id="status_id"
                                            name="status_id"
                                            labelId="label_status_id"
                                            label="Status"
                                            value={score.status_id}
                                            defaultValue={score.status_id}
                                            onChange={(e) => changeScore(e, score)}
                                            size="small"
                                            sx={{ width: '100%' }}
                                        >
                                            {(match_status.length > 0) ?
                                                match_status.map((item, key) => {
                                                    return <mui.MenuItem key={key} value={item.id}>{item.title}</mui.MenuItem>;
                                                })
                                                :
                                                <mui.MenuItem value="0">None</mui.MenuItem>
                                            }
                                        </mui.Select>
                                    </mui.TableCell>
                                </mui.TableRow>
                                <mui.TableRow className="row-other">
                                    <mui.TableCell size="small" align="right">Scoring Format :</mui.TableCell>
                                    <mui.TableCell size="small">
                                        <mui.Select
                                            id="score_format"
                                            name="score_format"
                                            labelId="label_score_format"
                                            label="Status"
                                            value={score.score_format}
                                            defaultValue={score.score_format}
                                            onChange={(e) => changeScore(e, score)}
                                            size="small"
                                            sx={{ width: '100%', maxWidth: '250px' }}
                                        >
                                            {(scoring_format.length > 0) ?
                                                scoring_format.map((item, key) => {
                                                    return <mui.MenuItem key={key} value={item.id}>{item.title}</mui.MenuItem>;
                                                })
                                                :
                                                <mui.MenuItem value="0">None</mui.MenuItem>
                                            }
                                        </mui.Select>
                                    </mui.TableCell>
                                </mui.TableRow>
                                <mui.TableRow className="row-other">
                                    <mui.TableCell size="small" align="right">Winner :</mui.TableCell>
                                    <mui.TableCell size="small">
                                        <WinnerPlayer
                                            match={match}
                                            score={score}
                                            changeScore={changeScore}
                                        />
                                    </mui.TableCell>
                                </mui.TableRow>
                                <mui.TableRow className="row-other">
                                    <mui.TableCell size="small" align="right">Chair Umpire :</mui.TableCell>
                                    <mui.TableCell size="small">{user.first_name + ' ' + user.last_name}</mui.TableCell>
                                </mui.TableRow>
                                <mui.TableRow className="row-other">
                                    <mui.TableCell size="small" align="right">Court :</mui.TableCell>
                                    <mui.TableCell size="small">{court.court_name}</mui.TableCell>
                                </mui.TableRow>
                            </mui.TableBody>
                        </mui.Table>
                        <mui.Stack direction="row" sx={{ justifyContent: 'center', mt: 2 }}>
                            <mui.Button
                                variant="outlined"
                                startIcon={<ArrowBack />}
                                color="error"
                                sx={{ mr: 2 }}
                                onClick={() => handleBack()}
                            >Back</mui.Button>
                            <mui.Button
                                variant="outlined"
                                endIcon={<SendIcon />}
                                color="success"
                                onClick={() => submitMatchScore(score)}
                            >Submit Score</mui.Button>
                        </mui.Stack>
                    </>
                )}
            </mui.Container>
        </>
    );
}

export default MatchScore;