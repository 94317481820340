import objectAssign from "object-assign";
import * as actionTypes from '../../constants/actionTypes';
import initialState from "./initialState";

export default function CourtReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_COURT_SUCCESS:
            return objectAssign({}, state, {
                court: {
                    ...state.court,
                    loading: false,
                    courts: action.data
                }
            });
        default:
            return state;
    }
}