import store from "store";
import expirePlugin from 'store/plugins/expire';
import * as storageTypes from '../constants/storageTypes';

store.addPlugin(expirePlugin);

export default class LocalStorage {
    static isLoggedIn() {
        const accessToken = store.get(storageTypes.ACCESS_TOKEN);
        return accessToken && accessToken.length > 0;
    }

    static persistLogin(access_token, expires_in_minutes, identity) {
        const convertToMilliseconds = expires_in_minutes * 60 * 1000;
        const expiration = new Date().getTime() + convertToMilliseconds;

        store.set(storageTypes.CURRENT_USER, identity);
        store.set(storageTypes.ACCESS_TOKEN, access_token, expiration);
    }

    static logout() {
        store.remove(storageTypes.ACCESS_TOKEN);
        store.remove(storageTypes.USER_ID);
        store.remove(storageTypes.CURRENT_USER);
        store.remove(storageTypes.TOURNAMENT_ID);
        store.remove(storageTypes.CURRENT_TOURNAMENT);
        store.remove(storageTypes.COURT_ID);
        store.remove(storageTypes.CURRENT_COURT);
        store.remove(storageTypes.CURRENT_MATCH);
    }

    static getAccessToken() {
        return store.get(storageTypes.ACCESS_TOKEN);
    }

    static setTourId(value) {
        store.set(storageTypes.TOURNAMENT_ID, value);
    }

    static getTourId() {
        return store.get(storageTypes.TOURNAMENT_ID);
    }

    static setCourtId(value) {
        store.set(storageTypes.COURT_ID, value);
    }

    static getCourtId() {
        return store.get(storageTypes.COURT_ID);
    }

    static setSelectedCourt(value) {
        store.set(storageTypes.CURRENT_COURT, value);
    }

    static getSelectedCourt() {
        return store.get(storageTypes.CURRENT_COURT);
    }

    static setUser(value) {
        store.set(storageTypes.CURRENT_USER, value);
    }

    static setUserId(value) {
        store.set(storageTypes.USER_ID, value);
    }

    static getUserId() {
        return store.get(storageTypes.USER_ID);
    }

    static setSelectedTournament(value) {
        store.set(storageTypes.CURRENT_TOURNAMENT, value);
    }

    static getSelectedTournament() {
        return store.get(storageTypes.CURRENT_TOURNAMENT);
    }

    static getLoggedInUser() {
        return store.get(storageTypes.CURRENT_USER);
    }

    static setCurrentMatch(value) {
        store.set(storageTypes.CURRENT_MATCH, value);
    }

    static getCurrentMatch() {
        return store.get(storageTypes.CURRENT_MATCH);
    }
}