import objectAssign from 'object-assign';
import * as actionTypes from '../../constants/actionTypes';
import initialState from './initialState';

export default function Reducers(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SHOW_LOADER:
            return objectAssign({}, state, {
                isLoading: true
            });
        case actionTypes.HIDE_LOADER:
            return objectAssign({}, state, {
                isLoading: false
            });
        default:
            return state;
    }
}