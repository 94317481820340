import * as ajaxRequest from '../../api/ajaxCallCreators';
import * as actionTypes from '../../constants/actionTypes';
import LocalStorage from '../../storage/LocalStorage';
import * as apiActions from '../actions/ApiActions';

/**
 * synchronous action creator
 */
export const login = (params) => {
    return async (dispatch) => {
        try {
            dispatch(apiActions.showLoader());
            await ajaxRequest.post('user-login', params, 'login', false).then(response => {
                dispatch(apiActions.hideLoader());
                if (response.success) {
                    let res = response.data;
                    LocalStorage.persistLogin(res.api_token, 720, res);
                    dispatch(validateLogin({ error: false, message: '' }));
                    dispatch(userLogin(res));
                } else {
                    dispatch(validateLogin({
                        error: true,
                        message: response.message
                    }));
                }
            }).catch((error) => {
                dispatch(apiActions.hideLoader());
                throw error;
            });
        } catch (error) {
            throw error;
        }
    }
}

export const selectedUser = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: actionTypes.SELECT_USER_SUCCESS
            });
        } catch (error) {
            throw error;
        }
    }
}

export const changeLoginForm = (data) => {
    return {
        type: actionTypes.CHANGE_LOGIN_FORM,
        data: data
    }
}

export const validateLogin = (data) => {
    return {
        type: actionTypes.VALIDATE_LOGIN_FORM,
        data: data
    }
}

const userLogin = (response) => {
    return {
        type: actionTypes.USER_LOGIN_SUCCESS,
        data: response
    }
}