import objectAssign from "object-assign";
import * as actionTypes from '../../constants/actionTypes';
import LocalStorage from "../../storage/LocalStorage";
import initialState from "./initialState";

export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.USER_LOGIN_SUCCESS:
            return objectAssign({}, state, {
                user: {
                    ...state.user,
                    loading: false,
                    isLoggedIn: true,
                    user: action.data
                }
            });
        case actionTypes.SELECT_USER_SUCCESS:
            return objectAssign({}, state, {
                user: {
                    ...state.user,
                    loading: false,
                    loginForm: {
                        ...state.user.loginForm,
                        tour_id: parseInt(LocalStorage.getTourId()),
                        primary_id: LocalStorage.getUserId(),
                    },
                    user: LocalStorage.getLoggedInUser()
                }
            });
        case actionTypes.CHANGE_LOGIN_FORM:
            return objectAssign({}, state, {
                user: action.data
            });
        case actionTypes.VALIDATE_LOGIN_FORM:
            return objectAssign({}, state, {
                user: {
                    ...state.user,
                    loginValidate: action.data
                }
            });
        default:
            return state;
    }
}