import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
/*** Imports Components ***/
import PageNotFound from "./PageNotFound";
import HomeContainers from '../containers/HomeContainers';
import TournamentContainers from '../containers/TournamentContainers';
import CourtContainers from "../containers/CourtContainers";
import UserContainers from "../containers/UserContainers";
import LoginContainers from "../containers/LoginContainers";
import MatchLIstContainers from "../containers/MatchLIstContainers";
import MatchScoreContainers from "../containers/MatchScoreContainers";

const AppRoutes = (props) => {
    return (
        <BrowserRouter {...props}>
            <Routes>
                <Route exact path="/" element={<HomeContainers />} />
                <Route path="/tournament" element={<TournamentContainers />} />
                <Route path="/users/:tourId" element={<UserContainers />} />
                <Route path="/login" element={<LoginContainers />} />
                <Route exact path="/" element={<PrivateRoute />}>
                    <Route path="/courts" element={<CourtContainers />} />
                    <Route path="/match-list/:courtId" element={<MatchLIstContainers />} />
                    <Route path="/match-score" element={<MatchScoreContainers />} />
                </Route>
                <Route element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;